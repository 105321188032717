import {
  BoundDateField,
  BoundNumberField,
  Button,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useHistory } from "react-router";
import { SaveCreditFacilityPaymentInput, useCreateCreditFacilityPaymentMutation } from "src/generated/graphql-types";
import { createPaymentDetailsUrl } from "src/RouteUrls";
import { DateOnly } from "src/utils/dates";

type CreatePaymentModalProps = {
  fundId: string;
};

export function CreatePaymentModal({ fundId }: CreatePaymentModalProps) {
  const formState = useFormState({
    config: formConfig,
    init: {
      input: {
        amountInCents: 0,
        paymentDate: new DateOnly(new Date()),
      },
    },
  });
  const { closeModal } = useModal();
  const [saveCreditFacilityPayment] = useCreateCreditFacilityPaymentMutation();
  const history = useHistory();

  return (
    <>
      <ModalHeader>Create Credit Facility Payment</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundDateField field={formState.paymentDate} label="Date" />
          <BoundNumberField field={formState.amountInCents} type="cents" label="Payment Amount" />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button label="Cancel" onClick={closeModal} variant="secondary" />
              <Button
                disabled={!formState.valid}
                label="Create"
                onClick={async () => {
                  if (formState.canSave()) {
                    const response = await saveCreditFacilityPayment({
                      variables: {
                        input: {
                          ...mapToInput(formState.value),
                          fundId,
                        },
                      },
                    });
                    const newPaymentId = response.data?.saveCreditFacilityPayment.creditFacilityPayment.id;
                    if (newPaymentId) {
                      // close global modal then redirect
                      closeModal();
                      history.push({ pathname: createPaymentDetailsUrl(newPaymentId) });
                    }
                  }
                }}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormInput = Pick<SaveCreditFacilityPaymentInput, "amountInCents"> & {
  paymentDate: Date | null | undefined;
};

const formConfig: ObjectConfig<FormInput> = {
  paymentDate: { type: "value", rules: [required] },
  amountInCents: { type: "value", rules: [required] },
};

function mapToInput(form: FormInput): SaveCreditFacilityPaymentInput {
  const { paymentDate, ...others } = form;
  return {
    paymentDate: paymentDate && new DateOnly(paymentDate),
    ...others,
  };
}
