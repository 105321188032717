import { BoundSelectField, Button, FormLines, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { ObjectConfig, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import {
  AddOpenDrawsToCreditFacilityPaymentInput,
  PaymentOverviewPageLotFragment,
  useAddDrawsToPaymentMutation,
} from "src/generated/graphql-types";

type AddLotPartitionDrawsPaymentModalProps = {
  paymentId: string;
  lots: PaymentOverviewPageLotFragment[];
};

type LotPartitionInput = {
  streetAddress: string;
  lotPartitionId: string;
  blueprintProjectId: string;
};

export function AddLotPartitionDrawsPaymentModal({ paymentId, lots }: AddLotPartitionDrawsPaymentModalProps) {
  const formState = useFormState({
    config: formConfig,
    init: {
      input: {
        lotPartitionId: null,
      },
    },
  });
  const { closeModal } = useModal();
  const [addDrawsToPayment] = useAddDrawsToPaymentMutation();

  const lotPartitions: LotPartitionInput[] = lots.flatMap((lot) =>
    lot.partitions.map((lotPartition) => {
      return {
        streetAddress: lot.address!.street1,
        lotPartitionId: lotPartition.id,
        blueprintProjectId: lotPartition.blueprintProjectId || "",
      };
    }),
  );

  return (
    <>
      <ModalHeader>Add Payments for Open Draws for Lot Partition</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundSelectField
            field={formState.lotPartitionId}
            options={lotPartitions}
            getOptionValue={(o) => o.lotPartitionId}
            getOptionLabel={(o) => `${o.streetAddress} - ${o.blueprintProjectId}`}
          />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button label="Cancel" onClick={closeModal} variant="secondary" />
              <Button
                disabled={!formState.value.lotPartitionId}
                label="Add for Project"
                onClick={async () => {
                  if (formState.canSave()) {
                    await addDrawsToPayment({
                      variables: {
                        input: mapToInput(formState.value, paymentId),
                      },
                    });
                    // close global modal then redirect back to the page with refresh
                    closeModal();
                    window.location.reload();
                  }
                }}
              />
              <Button
                disabled={false}
                label="Add All Draws for the Fund"
                onClick={async () => {
                  if (formState.canSave()) {
                    await addDrawsToPayment({
                      variables: {
                        input: { creditFacilityPaymentId: paymentId, lotPartitionIds: null },
                      },
                    });
                    // close global modal then redirect back to the page with refresh
                    closeModal();
                    window.location.reload();
                  }
                }}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormInput = Omit<AddOpenDrawsToCreditFacilityPaymentInput, "creditFacilityPaymentId" | "lotPartitionIds"> & {
  lotPartitionId: string | null | undefined;
};

const formConfig: ObjectConfig<FormInput> = {
  lotPartitionId: { type: "value" },
};

function mapToInput(form: FormInput, paymentId: string): AddOpenDrawsToCreditFacilityPaymentInput {
  const { lotPartitionId } = form;
  return {
    creditFacilityPaymentId: paymentId,
    lotPartitionIds: lotPartitionId ? [lotPartitionId] : null,
  };
}
