import { AuthenticatedRoute, AuthViewProps, GoogleCallback, UnauthenticatedRoute } from "@homebound/auth-components";
import { Redirect, Switch } from "react-router-dom";
import { LotsPage } from "src/routes/lots/LotsPage";
import { SignIn } from "./routes/auth/SignIn";
import { DrawOverviewPage } from "./routes/funds/draws/DrawOverviewPage";
import { FundsPage } from "./routes/funds/FundsPage";
import { InvoiceOverviewPage } from "./routes/funds/invoices/InvoiceOverviewPage";
import { FundOverviewPage } from "./routes/funds/overview/FundOverviewPage";
import { PaymentOverviewPage } from "./routes/funds/payments/PaymentOverviewPage";
import { LotOverviewPage } from "./routes/lots/overview/LotOverviewPage";
import {
  drawPath,
  drawRequestPath,
  fundLoanManagementPath,
  fundPath,
  fundsPath,
  invoicePath,
  lotAcqSettlementStatementPath,
  lotPath,
  lotsPath,
  metrosPath,
  paymentPath,
} from "./routes/routesDef";
import { MetrosPage } from "./routes/metros/MetrosPage";
import { DrawRequestOverviewPage } from "./routes/funds/drawRequests/DrawRequestOverviewPage";
import { FundLoanManagementPage } from "./routes/funds/loanManagement/FundLoanManagementPage";
import { LotAcqSettlementStatementEditor } from "./routes/lots/acqSettlementStatement/LotAcqSettlementStatementEditor";

export type ProjectRouteProps = { projectId: string };

export function Routes({ authProps }: { authProps: AuthViewProps }) {
  return (
    <Switch>
      <Redirect exact from="/" to={lotsPath} />
      <AuthenticatedRoute path={lotsPath} component={LotsPage} authProps={authProps}></AuthenticatedRoute>
      <AuthenticatedRoute path={lotPath} component={LotOverviewPage} authProps={authProps}></AuthenticatedRoute>

      <AuthenticatedRoute path={fundsPath} component={FundsPage} authProps={authProps}></AuthenticatedRoute>
      <AuthenticatedRoute path={fundPath} component={FundOverviewPage} authProps={authProps}></AuthenticatedRoute>
      <AuthenticatedRoute
        path={fundLoanManagementPath}
        component={FundLoanManagementPage}
        authProps={authProps}
      ></AuthenticatedRoute>

      <AuthenticatedRoute path={drawPath} component={DrawOverviewPage} authProps={authProps}></AuthenticatedRoute>
      <AuthenticatedRoute
        path={drawRequestPath}
        component={DrawRequestOverviewPage}
        authProps={authProps}
      ></AuthenticatedRoute>
      <AuthenticatedRoute path={paymentPath} component={PaymentOverviewPage} authProps={authProps}></AuthenticatedRoute>
      <AuthenticatedRoute path={invoicePath} component={InvoiceOverviewPage} authProps={authProps}></AuthenticatedRoute>

      <AuthenticatedRoute path={metrosPath} component={MetrosPage} authProps={authProps}></AuthenticatedRoute>
      <AuthenticatedRoute
        path={lotAcqSettlementStatementPath}
        component={LotAcqSettlementStatementEditor}
        authProps={authProps}
      ></AuthenticatedRoute>

      <UnauthenticatedRoute path="/signIn" exact component={SignIn} authProps={authProps} />
      <UnauthenticatedRoute path="/auth/gcallback" exact component={GoogleCallback} authProps={authProps} />
    </Switch>
  );
}
