import {
  BoundDateField,
  BoundNumberField,
  Button,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { ExportBillsForDrawInput, useExportBillsForDrawMutation } from "src/generated/graphql-types";
import { DateOnly } from "src/utils/dates";

type CreateBillExportModalProps = {
  fundId: string;
  endDate: DateOnly | undefined;
  minimumBillAmountInCents: number | undefined;
};

export function BillExportModal(props: CreateBillExportModalProps) {
  const { fundId, endDate, minimumBillAmountInCents } = props;
  const formState = useFormState({
    config: formConfig,
    init: {
      input: {
        minBillAmountInCents: minimumBillAmountInCents || 0,
        startDate: new DateOnly(new Date()),
        endDate: endDate || new DateOnly(new Date()),
        fundId,
      },
    },
  });
  const { closeModal } = useModal();
  const [exportBillsForDraw] = useExportBillsForDrawMutation();

  return (
    <>
      <ModalHeader>Export Blueprint Bills For Credit Facility Draw</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundDateField field={formState.startDate} label="Start Date (Inclusive)" />
          <BoundDateField field={formState.endDate} label="End Date (Inclusive)" />
          <BoundNumberField field={formState.minBillAmountInCents} type="cents" label="Minimum Bill Amount" />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button label="Cancel" onClick={closeModal} variant="secondary" />
              <Button
                disabled={!formState.valid}
                label="Export Bills"
                tooltip="Please allow 15-20 min for export to complete"
                onClick={async () => {
                  if (formState.canSave()) {
                    const response = await exportBillsForDraw({
                      variables: {
                        input: {
                          ...mapToInput(formState.value),
                          fundId,
                        },
                      },
                    });
                    const googleDriveFolderId = response.data?.exportBillsForDraw.googleDriveFolderId;
                    if (googleDriveFolderId) {
                      // close global modal then redirect
                      closeModal();
                      // Redirect to the google drive folder
                      window.open(
                        `https://drive.google.com/drive/u/1/folders/${googleDriveFolderId}`,
                        "_blank",
                        "noopener,noreferrer",
                      );
                    }
                  }
                }}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormInput = Pick<ExportBillsForDrawInput, "minBillAmountInCents" | "fundId"> & {
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
};

const formConfig: ObjectConfig<FormInput> = {
  startDate: { type: "value", rules: [required] },
  endDate: { type: "value", rules: [required] },
  minBillAmountInCents: { type: "value", rules: [required] },
  fundId: { type: "value", rules: [required] },
};

function mapToInput(form: FormInput): ExportBillsForDrawInput {
  const { startDate, endDate, ...others } = form;
  const startDateInput = startDate && new DateOnly(startDate);
  const endDateInput = endDate && new DateOnly(endDate);
  return {
    startDate: startDateInput!,
    endDate: endDateInput!,
    ...others,
  };
}
