import {
  Button,
  column,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
  useGridTableApi,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { format } from "date-fns";
import { useMemo } from "react";
import { BoundBeamDateField } from "src/components/BoundBeamDateField";
import {
  FundOverviewPageExpectedSaleDateFundFragment,
  FundOverviewPageExpectedSaleDateLotPartitionFragment,
  Maybe,
  SaveLotPartitionInput,
  useFundOverviewExpectedSaleDateFundQuery,
  useSaveLotPartitionsMutation,
} from "src/generated/graphql-types";
import { DateOnly, formatWithYear } from "src/utils/dates";
import { queryResult } from "src/utils/queryResult";

export type LotPartitionExpectedSaleDateTableProps = {
  fundId: string;
};

type FormInput = SaveLotPartitionInput & {
  lotPartitions: Maybe<SaveLotPartitionInput[]>;
};

export function LotPartitionExpectedSaleDateTable(props: LotPartitionExpectedSaleDateTableProps) {
  const { fundId } = props;

  const query = useFundOverviewExpectedSaleDateFundQuery({ variables: { fundId: fundId } });

  const [saveLotPartition] = useSaveLotPartitionsMutation();
  async function saveLotPartitions(formState: ObjectState<FormInput>) {
    const changedLotPartitions =
      formState.changedValue.lotPartitions?.filter((lp) => lp.manualExpectedSaleDate !== undefined) || [];
    await Promise.all(
      changedLotPartitions.map(async (lp) =>
        saveLotPartition({
          variables: { input: { lotPartitions: [{ ...lp }] } },
        }),
      ),
    );
    await query.refetch();
  }

  return queryResult(query, ({ funds }) => {
    const fund = funds[0];
    return <ExpectedSaleDateTable fund={fund} saveLotPartitions={saveLotPartitions} />;
  });
}

export type ExpectedSaleDateTableProps = {
  fund: FundOverviewPageExpectedSaleDateFundFragment;
  saveLotPartitions: (formState: ObjectState<FormInput>) => Promise<void>;
};

function ExpectedSaleDateTable(props: ExpectedSaleDateTableProps) {
  const lotPartitions = (props.fund.lots.flatMap((lot) => lot.partitions) || [])
    .compact()
    .sortBy((lp) => lp.blueprintProjectId || "")
    .reverse();

  const formConfig: ObjectConfig<FormInput> = useMemo(
    () => ({
      id: { type: "value" },
      lotPartitions: {
        type: "list",
        config: lotPartitionConfig,
      },
    }),
    [],
  );
  const formState = useFormState({
    config: formConfig,
    init: {
      input: lotPartitions,
      map: mapToForm,
    },
    autoSave: props.saveLotPartitions,
  });

  const tableApi = useGridTableApi<Row>();
  const csvName = props.fund.name + "_ExpectedSaleDates_" + format(Date.now(), "ddMMMyy") + ".csv";

  return (
    <>
      <GridTable
        id="lotPartitionExpectedSaleDateTable"
        rowStyles={rowStyles}
        columns={createLotPartitionExpectedSaleDateColumns(formState)}
        rows={createLotPartitionExpectedSaleDateRows(lotPartitions)}
        fallbackMessage="Sale Dates for the Lot Partitions will show here."
        sorting={{ on: "client" }}
        api={tableApi}
      />
      <Button onClick={() => tableApi.downloadToCsv(csvName)} label="Download CSV" />
    </>
  );
}

type Row = SimpleHeaderAndData<FundOverviewPageExpectedSaleDateLotPartitionFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function createLotPartitionExpectedSaleDateRows(
  lotPartitions: FundOverviewPageExpectedSaleDateLotPartitionFragment[],
): GridDataRow<Row>[] {
  return simpleDataRows(lotPartitions);
}

function createLotPartitionExpectedSaleDateColumns(formState: ObjectState<FormInput>): GridColumn<Row>[] {
  const lotPartitionIdColumn = column<Row>({
    header: () => "Id",
    data: ({ id }) => id,
    sticky: "left",
    w: "100px",
  });

  const blueprintIdColumn = column<Row>({
    header: () => "BlueprintProjectId",
    data: ({ blueprintProjectId }) => blueprintProjectId,
    sticky: "left",
    w: "100px",
  });

  const addressColumn = column<Row>({
    header: () => "Lot Address",
    data: ({ lot }) => lot.address?.street1,
    sticky: "left",
    w: "300px",
  });

  const manualExpectedSaleDateColumn = column<Row>({
    header: () => "Manual Expected Sale Date",
    data: (row) => {
      const formStateField = formState.lotPartitions.rows.find((lp) => lp.id.value === row.id);
      return {
        value: () => row.manualExpectedSaleDate,
        content: () => <BoundBeamDateField field={formStateField!.manualExpectedSaleDate} />,
        tooltip: "The date we expect to sell the property (manually set)",
      };
    },
    w: "150px",
  });

  const saleDateColumn = column<Row>({
    header: () => "Sold Date",
    data: ({ saleDate }) => (saleDate ? formatWithYear(saleDate) : "-"),
    sticky: "left",
    w: "300px",
  });

  const expectedSaleDateColumn = column<Row>({
    header: () => "Expected Sale Date",
    data: ({ expectedSaleDate }) => (expectedSaleDate ? formatWithYear(expectedSaleDate) : "-"),
    sticky: "left",
    w: "300px",
  });

  return [
    lotPartitionIdColumn,
    blueprintIdColumn,
    addressColumn,
    manualExpectedSaleDateColumn,
    saleDateColumn,
    expectedSaleDateColumn,
  ];
}

function mapToForm(lotPartitions: FundOverviewPageExpectedSaleDateLotPartitionFragment[]): FormInput {
  return {
    lotPartitions: mapLotPartitionsToForm(lotPartitions),
  };
}

export function mapLotPartitionsToForm(lotPartitions: Maybe<FundOverviewPageExpectedSaleDateLotPartitionFragment[]>) {
  return lotPartitions?.map((lp) => {
    return {
      id: lp.id,
      manualExpectedSaleDate: lp.manualExpectedSaleDate ? new DateOnly(lp.manualExpectedSaleDate) : undefined,
    };
  });
}

type FormValueRevision = Pick<SaveLotPartitionInput, "id"> & {
  manualExpectedSaleDate: DateOnly | null | undefined;
};

export const lotPartitionConfig: ObjectConfig<FormValueRevision> = {
  id: { type: "value" },
  manualExpectedSaleDate: { type: "value" },
};
